<template>
  <div id="page-ponta">
    <div class="xs-p-20 xs-fill-white">
      <div class="form-section first-section">
        <div v-html="$t('ponta.title_memo')"></div>
        <div class="form-field">
          <div class="xs-flex xs-items-center xs-mb-5">
            <label class="label label-input xs-mb-0" for="id">{{$t('ponta.id')}}<span class="cl-red">{{ $t('required') }}</span></label>
            <span v-if="errors.id" class="xs-text-right typo-l7 xs-text-red xs-ml-auto error-text">
                                          <span>{{ errors.id }}</span><i class="icon-error"></i>
                </span>
          </div>
          <input id="id" ref="id" class="input xs-mb-10  "
                 v-bind:class="{ 'has-errors': errors.id , 'has-success': errors.id === false}"
                 name="lastName" v-model="ponta.id" value="" v-bind:placeholder="$t('ponta.id_placehover')"
                 type="text">
        </div>
        <div class="form-field">
          <div class="xs-flex xs-items-center xs-mb-5">
            <label class="label label-input xs-mb-0 text-left whitespace-normal">{{$t('ponta.number_title')}}<span class="cl-red">{{ $t('required') }}</span></label>
            <span v-if="errors.number" class="xs-text-right typo-l7 xs-text-red xs-ml-auto error-text">
                                          <span>{{ errors.number }}</span><i class="icon-error"></i>
                </span>
          </div>
          <div class="xs-flex xs-items-center">
            <input id="number1" ref="number1" class="input xs-mb-10  "
                   v-model="ponta.number.number1" value="" v-bind:placeholder="$t('ponta.number1_placehover')" maxlength="3"
                   type="text">
            <span class="space_both_side"> - </span>
            <input id="number2" ref="number2" class="input xs-mb-10  "
                   v-model="ponta.number.number2" value="" v-bind:placeholder="$t('ponta.number2_placehover')" maxlength="4"
                   type="text">
            <span class="space_both_side"> - </span>
            <input id="number3" ref="number3" class="input xs-mb-10  "
                   v-model="ponta.number.number3" value="" v-bind:placeholder="$t('ponta.number3_placehover')" maxlength="4"
                   type="text">
          </div>

        </div>
        <p class="notice-title t15" > *{{$t('ponta.notice')}}*</p>
        <p v-html="$t('ponta.notice_content')"></p>
        <div class="clearfix pb-20"></div>
        <div class="action-group text-center">
          <button class="btn btn-success" @click="checkForm"> {{$t('btn_save')}}</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {
  },
  watch: {
    'ponta.id': function (newValue, oldValue) {
      if (!this.ponta.id) {
        this.errors.id = this.$t('required_display')
      } else {
        this.errors.id = false
      }
    },
    'ponta.number.number1': function (newValue, oldValue) {
      let numbers = /^[-+]?[0-9]+$/
      if (newValue && !newValue.match(numbers)) {
        this.$set(this.ponta.number, 'number1', oldValue)
      }
    },
    'ponta.number.number2': function (newValue, oldValue) {
      let numbers = /^[-+]?[0-9]+$/
      if (newValue && !newValue.match(numbers)) {
        this.$set(this.ponta.number, 'number2', oldValue)
      }
    },
    'ponta.number.number3': function (newValue, oldValue) {
      let numbers = /^[-+]?[0-9]+$/
      if (newValue && !newValue.match(numbers)) {
        this.$set(this.ponta.number, 'number3', oldValue)
      }
    }
  },
  data () {
    return {
      ponta: {
        id: '',
        number: {
          number1: '',
          number2: '',
          number3: ''
        }
      },
      errors: {
        'id': '',
        'number1': '',
        'number2': '',
        'number3': ''
      }
    }
  },
  mounted () {
  },
  methods: {
    checkForm () {
      let flag = false
      this.errors = {
        'id': '',
        'number1': '',
        'number2': '',
        'number3': ''
      }

      if (!this.ponta.id) {
        this.errors.id = this.$t('required_display')

        this.$refs.id.focus()
        flag = true
      } else {
        this.errors.id = false
      }
      this.errors.number = false
      if (!this.ponta.number.number1) {
        this.errors.number = this.$t('required_display')
        if (!flag) {
          this.$refs.number1.focus()
        }
        flag = true
      } else if (!this.ponta.number.number2) {
        this.errors.number = this.$t('required_display')
        if (!flag) {
          this.$refs.number2.focus()
        }
        flag = true
      } else if (!this.ponta.number.number3) {
        this.errors.number = this.$t('required_display')
        if (!flag) {
          this.$refs.number3.focus()
        }
        flag = true
      }

      if (flag) {
        return false
      }
      // call api to save info
      this.$notify({
        type: 'success',
        title: 'success'
      })
      return true
    }

  }
}

</script>
 <style scoped>
   .space_both_side{
     padding: 0 10px;
     margin-top: -10px;
   }
   .notice-title {
     color: #ff9123;
   }
 </style>
